import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import methodModel from "../../../methods/methods";
import ApiClient from "../../../methods/api/apiClient";
import moment from "moment";

const Html = ({ ListItemLink, tabclass, urlAllow, route }) => {
  const user = useSelector((state) => state.user);
  const activePlan = useSelector((state) => state.activePlan);
  const history = useNavigate();

  const DowngradePlan = () => {
    const current_date = moment();
    const activePlanValidUpto = moment(activePlan?.valid_upto);    

    if (activePlanValidUpto < current_date && activePlan?.valid_upto != null && user?.id) {
      ApiClient.get(`downgrade/plan`).then((res) => {
        if (res.success) {

        }
      })
    } else {
      console.log("trial plan was not expire")
    }
  }

  useEffect(() => {
    if (user) {
      setInterval(() => {
        DowngradePlan();
      }, 5000)
    }
  }, []);

  return (
    <>
      <ul className="nav flex-column siderbar_layout mt-4" component="siderbar">
        <ListItemLink to="/app/qrcodelisting">
          <i className="material-icons me-2">qr_code</i>
          <span className="side_head">QR Codes</span>
        </ListItemLink>
        <ListItemLink to="/app/analytics">
          <i className="material-icons me-2">analytics</i>
          <span className="side_head">Analytics</span>
        </ListItemLink>
        <ListItemLink to="/app/folder">
          <i className="material-icons me-2">folder</i>
          <span className="side_head">Folders</span>
        </ListItemLink>
        <ListItemLink to="/app/template">
          <i className="material-icons me-2">texture</i>
          <span className="side_head">Templates</span>
        </ListItemLink>
        <ListItemLink to="/app/trash">
          <i className="material-icons me-2">delete</i>
          <span className="side_head">Trash</span>
        </ListItemLink>
        <ListItemLink to="/app/accountsetting">
          <i className="material-icons me-2">settings</i>
          <span className="side_head">Settings</span>
        </ListItemLink>
        <ListItemLink to="/app/qrscanner">
          <i className="material-icons me-2">qr_code_scanner</i>
          <span className="side_head">QR Scanner</span>
        </ListItemLink>
      </ul>

      {!activePlan?.planActive || Number(activePlan?.amount) == 0 ? null : (
        <div className="bonus_div">
          <h1>REFERRAL BONUS</h1>
          <p>
            <span className="bonus_number">
              {activePlan?.affiliate_commission}%
            </span>
            Commissions
          </p>
          <button
            onClick={(e) => {
              history("/app/referral");
            }}
            className="btn primary_btn2 text-center form-control text-white bonus_btn"
          >
            Affiliate Program{" "}
            <span class="material-icons ms-2">card_giftcard</span>
          </button>
        </div>
      )}
      <div className="sidebar_box">
        <div className="profile d-flex align-items-center gap-2 p-2">
          <img
            src={methodModel.userImg(user?.image)}
            clasas="img-fluid"
            width="32px"
            height="32px"
            alt="..."
          ></img>
          <div className="alex-text">
            <h6 className="mb-0">{user?.fullName || ""}</h6>
            <p className="mb-0">{user?.email || "--"}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Html;
